<template>
  <Preloader :is-loading="isLoading"></Preloader>
  <div class="card p-0 mt-5">
    <div class="table-responsive">
      <table class="table table-items">
        <thead>
          <tr>
            <th scope="col">
              <h1 class="fs-14 fw-bold color-gray">
                <input type="checkbox" id="checkAll" />
                <span class="ml-3">NAME</span>
              </h1>
            </th>
            <th scope="col">
              <h1 class="fs-14 fw-bold color-gray">EMAIL ADDRESS</h1>
            </th>
            <th scope="col">
              <h1 class="fs-14 fw-bold color-gray">PHONE NUMBER</h1>
            </th>
            <th scope="col">
              <h1 class="fs-14 fw-bold color-gray">LOCATION</h1>
            </th>
            <th scope="col">
              <h1 class="fs-14 fw-bold color-gray">BIRTHDAY</h1>
            </th>
            <th scope="col">
              <h1 class="fs-14 fw-bold color-gray">ZODIAC SIGN</h1>
            </th>
            <th scope="col">
              <h1 class="fs-14 fw-bold color-gray">STATUS</h1>
            </th>
            <th scope="col">
              <h1 class="fs-14 fw-bold color-gray">LAST SIGN IN</h1>
            </th>
            <th scope="col">
              <h1 class="fs-14 fw-bold color-gray text-center">MEMBERSHIPS</h1>
            </th>
            <th scope="col">
              <h1 class="fs-14 fw-bold color-gray text-center">TAKE ACTION</h1>
            </th>
            <th scope="col">
              <h1 class="fs-14 fw-bold color-gray text-center">NOTES</h1>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in userList" :key="row.in" class="fs-14">
            <td>
              <div class="icheck-primary d-inline">
                <input type="checkbox" id="checkboxPrimary1" />
                <span
                  class="ml-3 link-name"
                  data="modal"
                  @click="showProfile(row)"
                >
                  <img
                    class="rounded mr-3 tbl-img"
                    :src="row.primary_photo?.sizes?.thumbnail"
                    @error="imageUrlAlt"
                  />
                  {{ row.first_name }} {{ row.last_name }}
                </span>
              </div>
            </td>
            <td>{{ row.email }}</td>
            <td>{{ row.mobile_number }}</td>
            <td>
              {{
                row.profile !== null
                  ? row.profile.city + " " + row.profile.state
                  : ""
              }}
            </td>
            <td>{{ moment(row.birth_date).format("MMMM Do, YYYY") }}</td>
            <td>{{ row.zodiac_sign.value }}</td>
            <td>
              {{
                row.deleted_at !== null
                  ? "Deleted"
                  : row.is_suspended && row.deleted_at === null
                  ? "Suspended"
                  : row.status === 4 && row.deleted_at === null
                  ? "Flagged"
                  : row.status === 3 && row.deleted_at === null
                  ? "Deactivated"
                  : row.status === 0 && row.deleted_at === null
                  ? "Unverified"
                   : row.status === 1 && row.deleted_at === null
                  ? "Verified"
                  : "Active"
              }}
            </td>
            <td v-html="getLastLogin(row.last_login_at)"></td>
            <td class="dropdown text-center">
              <a class="nav-link" data-toggle="dropdown" href="#">
                View Memberships
              </a>
              <div class="dropdown-menu dropdown-menu-lg dropdown-menu-left">
                <router-link
                  v-for="(type, i) in memberships"
                  :key="i"
                  :to="`/user-${type.toLowerCase()}/${row.user_name}`"
                  class="dropdown-item p-2"
                >
                  {{ type }}
                </router-link>
              </div>
            </td>
            <td class="text-center">
              <div v-if="row.deleted_at === null">
                <div v-if="selectedTakeAction?.link === 'message'">
                  <a
                      href="#"
                      @click="goToAction(selectedTakeAction.link, row)"
                      class="p-2"
                    >
                     {{ selectedTakeAction.label }}
                    </a>
                </div>
                <div v-else>
                  <a class="nav-link" data-toggle="dropdown" href="#">
                    Take Action
                  </a>
                  <div class="dropdown-menu dropdown-menu-left">
                    <a
                      href="#"
                      @click="goToAction('reset-password', row)"
                      class="dropdown-item p-2"
                    >
                      Reset Password
                    </a>
                    <a
                      href="#"
                      @click="goToAction('update-profile', row)"
                      class="dropdown-item p-2"
                    >
                      Edit User Information
                    </a>
                    <a
                      href="#"
                      @click="goToAction('message', row)"
                      class="dropdown-item p-2"
                    >
                      Message
                    </a>
                    <a
                      v-if="row.status === 4 && row.deleted_at === null"
                      href="#"
                      @click="goToAction('remove-flag', row)"
                      class="dropdown-item p-2"
                    >
                      Remove Flag
                    </a>
                    <a
                      v-else
                      href="#"
                      @click="goToAction('flag', row)"
                      class="dropdown-item p-2"
                    >
                      Flag
                    </a>
                    <a
                      v-if="row.is_suspended && row.deleted_at === null"
                      href="#"
                      @click="goToAction('unsuspend', row)"
                      class="dropdown-item p-2"
                    >
                      Unsuspend
                    </a>
                    <a
                      v-else
                      href="#"
                      @click="goToAction('suspend', row)"
                      class="dropdown-item p-2"
                    >
                      Suspend
                    </a>
                    <a
                      v-if="
                        row.status === 3 && row.deleted_at === null
                      "
                      href="#"
                      @click="goToAction('reactivate', row)"
                      class="dropdown-item p-2"
                    >
                      Reactivate
                    </a>
                    <a
                      v-else
                      href="#"
                      @click="goToAction('deactivate', row)"
                      class="dropdown-item p-2"
                    >
                      Deactivate
                    </a>
                    <a
                      href="#"
                      @click="goToAction('delete', row)"
                      class="dropdown-item p-2"
                    >
                      Delete
                    </a>
                  </div>
                </div>
              </div>
                <div v-else class="text-red">Not Available</div>
            </td>
            <td class="text-center">
              <span v-if="row.user_notes.length === 0">
                <i class="fas fa-circle-plus mr-2" @click="addNotes(row)"></i>
              </span>
              <span v-else>
                <i
                  class="fas fa-pen-to-square mr-2"
                  @click="editNotes(row)"
                ></i>
                <i
                  class="fas fa-magnifying-glass-plus"
                  @click="viewNotes(row)"
                ></i>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <pagination-component
      class="mt-3 mb-2 mr-5"
      :total-pages="totalPage"
      :per-page="25"
      :current-page="currentPage"
      @pagechanged="onPageChange"
    />
  </div>

  <modal-component :modal-id="`modalId`">
    <template v-slot:modalBody>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-sm-12">
            <div class="row">
              <div class="col-md-5 col-lg-5 col-sm-12">
                <profile-image-component
                  v-if="userAccount"
                  :image-src="`${userAccount?.primary_photo?.modified}`"
                />
              </div>
              <div class="col-md-7 col-lg-7 col-sm-12">
                <user-information-component
                  v-if="userAccount"
                  :user="userAccount"
                  @cancel="cancel"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal-component>
  <modal-component
    :modal-id="`userSelectMessageType`"
    :title="`Who would you like to send a message to?`"
    :header-style="`bg-linear`"
    :size="`modal-md`"
    :profile="false"
  >
    <template v-slot:modalBody>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-sm-12">
            <div class="row">
              <div class="form-group">
                <div
                  class="card card-success"
                  :class="birthdayMessage ? 'selected-message-type' : ''"
                  @click="userSelectMessageType('selected_birthday')"
                >
                  <div class="p-3">
                    <div class="custom-control custom-radio">
                      <input
                        class="custom-control-input"
                        type="radio"
                        id="selected_birthday"
                        name="selectMessage"
                        disabled
                      />
                      <label
                        for="selected_birthday"
                        class="custom-control-label"
                        :style="birthdayMessage ? 'color: #cacaca' : ''"
                        >People who are celebrating birthday today</label
                      >
                    </div>
                    <small class="tbl-ll-date">{{
                      moment().format("ll")
                    }}</small>
                  </div>
                </div>
                <div
                  class="card card-success"
                  :class="selectedUser ? 'selected-message-type' : ''"
                  @click="userSelectMessageType('selected_user')"
                >
                  <div class="p-3">
                    <div class="custom-control custom-radio">
                      <input
                        class="custom-control-input background-gold"
                        type="radio"
                        id="selected_user"
                        name="selectMessage"
                        disabled
                      />
                      <label
                        for="selected_user"
                        class="custom-control-label"
                        :style="selectedUser ? 'color: #cacaca' : ''"
                        >Selected Users</label
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer border-0 float-right">
                <span
                  type="button"
                  class="btn-outline txt-color-gray"
                  @click="cancelMessageType"
                >
                  CANCEL
                </span>
                <span
                  type="button"
                  class="send"
                  @click="selectedUser || birthdayMessage ? nextMessage() : ''"
                >
                  NEXT
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal-component>
  <modal-component
    :modal-id="`specificMessage`"
    :title="`Message User?`"
    :header-style="`bg-linear`"
    :size="`modal-md`"
    :profile="false"
  >
    <template v-slot:modalBody>
      <message-modal
        :message-to="type"
        :selected-type="selectedType"
        :message-user="messageUser"
        @cancelMessage="cancelMessage"
      />
    </template>
  </modal-component>
  <modal-component
    :modal-id="`notes-modal`"
    :title="notesTitle"
    :header-style="`bg-linear`"
    :size="`modal-md`"
    :profile="false"
  >
    <template v-slot:modalBody>
      <notes-modal
        :notes-account="notesAccount"
        @done="done"
        :is-preview="isPreview"
        :notes="note"
        :is-edit="isEdit"
        :notes-type="`user`"
      />
    </template>
  </modal-component>

  <reset-password-modal
    :modal-id="`reset-password-modal`"
    :user-name="userNameAccountSelected"
    @actionNext="resetPasswordNext"
  />
</template>
<script>
import { onMounted, ref, watch, nextTick } from "vue";
import $ from "jquery";
import { useStore } from "vuex";
import moment from "moment";
import router from "@/router";
import { userEnums, GENERALSTATUS, TAKEACTION } from "@/composable/enums.js";
import ModalComponent from "@/components/ModalComponent.vue";
import ProfileImageComponent from "../../components/ProfileImageComponent.vue";
import UserInformationComponent from "../../Pages/User/UserInformationModal.vue";
import MessageModal from "../../components/Modal/MessageModal.vue";
import NotesModal from "../Modal/NotesModal.vue";
import Preloader from "../../Layouts/Common/Preloader.vue";
import PaginationComponent from "../PaginationComponent.vue";
import ResetPasswordModal from "../Modal/ResetPasswordModal.vue";
export default {
  name: "App",
  components: {
    ModalComponent,
    ProfileImageComponent,
    UserInformationComponent,
    MessageModal,
    NotesModal,
    Preloader,
    PaginationComponent,
    ResetPasswordModal,
  },
  props: {
    userType: {
      type: String,
    },
    filter: {
      type: String,
      default: "",
      required: false,
    },
    headerFilter: {
      type: String,
      default: "",
      required: false,
    },
  },
  emits: ["done"],
  setup(props, { emit }) {
    const store = useStore();
    const userList = ref([]);
    const userNameAccountSelected = ref("");
    const userAccount = ref("");
    const { memberships, userActions } = userEnums();
    const modalId = ref("view-profile");
    const isShowModal = ref(false);
    const messageUser = ref(null);
    const type = ref(null);
    const isMessage = ref(false);
    const birthdayMessage = ref(false);
    const selectedUser = ref(false);
    const isMessageShow = ref(false);
    const selectedType = ref(null);
    const selectedTakeAction = ref([]);
    const notesAccount = ref("");
    const isPreview = ref(false);
    const isEdit = ref(false);
    const note = ref(null);
    const isLoading = ref(false);
    const currentPage = ref(1);
    const totalPage = ref(null);
    const selectOption = ref("");
    const filterType = ref(""); 
    const notesTitle = ref('Create Note');

    const showResetPasswordModal = ref(false);

    const getLastLogin = (lastLogin) => {
      if (lastLogin !== "") {
        const timeago = moment(lastLogin).fromNow();
        if (timeago !== "Invalid date") {
          return timeago;
        }
      }
    };
    const showModal = () => {
      $(`#modalId`).modal("show");
    };
    const cancel = () => {
      isShowModal.value = false;
      $(`#modalId`).modal("hide");
    };
    const selectMessageToSend = () => {
      $(`#userSelectMessageType`).modal("show");
    };
    const loadUser = async (event) => {
      let response = [];
      if (props.filter !== null && event === undefined) {
        response = await store.dispatch(
          "searchUser",
          props.filter + "&&perPage=" + 25 + "&&page=" + currentPage.value
        );
      } else if (event === "headerFilter" && props.headerFilter !== null) {
        response = await store.dispatch(
          "userHeaderSearch",
          props.headerFilter + "&&perPage=" + 25 + "&&page=" + currentPage.value
        );
      } else {
        isLoading.value = true;
        response = await store.dispatch("loadUserList", {
          type: event,
          perPage: 25,
          page: currentPage.value,
        });
      }
      userList.value = response.data;
      if (response) {
        isLoading.value = false;
        totalPage.value = response.meta.last_page;
      } else {
        alert("something went wrong");
      }
    };
    const checkFlag = (row) => {
      let isFlag = row.filter((value) => value.type === "flag");
      return isFlag.length > 0 ? true : false;
    };
    const imageUrlAlt = (event) => {
      event.target.src = "//via.placeholder.com/150";
    };
    const userSelectMessageType = (type) => {
      if (type === "selected_birthday") {
        birthdayMessage.value = true;
        selectedUser.value = false;
      }
      if (type === "selected_user") {
        birthdayMessage.value = false;
        selectedUser.value = true;
      }
      if (type === "specific_user") {
        birthdayMessage.value = false;
        selectedUser.value = false;
      }
    };

    onMounted(() => {
      localStorage.setItem("prevURL", router.currentRoute._value.href);
      selectOption.value = router.currentRoute._value.query.type;
      filterType.value = router.currentRoute._value.query.filter;
      setFilter(selectOption.value ?? filterType.value);

      if (selectOption.value === undefined && filterType.value === undefined) {
        loadUser("all");
      }
    });
    const nextMessage = () => {
      $(`#userSelectMessageType`).modal("hide");
      $(`#specificMessage`).modal("show");
    };
    const goToAction = async (action, user) => {
      if (action === "message") {
        messageUser.value = user;
        type.value = "specific_user";
        selectedType.value = "User";
        userSelectMessageType("specific_user");
        nextMessage();
      } else if (action === "reset-password") {
        resetPassword(user.user_name);
      } else {
        console.log(action + ' ' + user.user_name)
        router.push(`/user/${action}/${user.user_name}`);
      }
    };
    const resetPasswordNext = (resetInfo) => {
      $("#reset-password-modal").modal("show");
      router.push(
        `/user/reset-password/${resetInfo.user_name}/${resetInfo.type}`
      );
    };
    // Close Modal
    const cancelMessage = () => {
      messageUser.value = null;
      birthdayMessage.value = false;
      selectedUser.value = false;
      $(`#specificMessage`).modal("hide");
    };
    const showProfile = async (user) => {
      emit("done", true);
      const response = await store.dispatch("loadUserProfile", {
        value: user.user_name,
      });
      localStorage.setItem("profile", response);
      userAccount.value = response;
      modalId.value = "user-" + user.id;
      $(`#modalId`).modal("show");
      emit("done", false);
    };
    const addNotes = (user) => {
      nextTick().then(() => {
        note.value = "";
        isPreview.value = false;
        isEdit.value = false;
        notesAccount.value = user;
        $("#notes-modal").modal("show");
      }, 250);
    };

    const resetPassword = (userName) => {
      userNameAccountSelected.value = userName;
      nextTick().then(() => {
        $("#reset-password-modal").modal("show");
      }, 250);
    };

    const editNotes = (account) => {
        isPreview.value = false;
      nextTick().then(() => {
        isEdit.value = true;
        notesAccount.value = account;
        note.value = account.user_notes;
        $("#notes-modal").modal("show");
      }, 250);
    };
    const viewNotes = (account) => {
        isPreview.value = true;
      nextTick().then(() => {
        isEdit.value = false;
        notesAccount.value = account;
        note.value = account.user_notes;
        notesTitle.value = 'View Notes';
        $("#notes-modal").modal("show");
      }, 250);
    };
    const done = () => {
      loadUser("all");
      notesAccount.value = "";
      $("#notes-modal").modal("hide");
    };
    const onPageChange = (page) => {
      currentPage.value = page;
    };

    const setFilter = (data) => {
       if (data === "active") {
        loadUser(TAKEACTION.ACTIVE);
      }
      if (data === "deactivated") {
        loadUser(GENERALSTATUS.DEACTIVATED);
      }
      if (data === "flagged") {
        loadUser(GENERALSTATUS.FLAGGED);
      }
      if (data === "suspended") {
        loadUser(GENERALSTATUS.SUSPENDED);
      }
      if (data === "deleted") {
        loadUser(GENERALSTATUS.DELETED);
      }
      if (data === "not verified") {
        loadUser(GENERALSTATUS.UNVERIFIED);
      }
      if (data === "verified") {
        loadUser(GENERALSTATUS.VERIFIED);
      }
      if (data === "unverified") {
        loadUser(GENERALSTATUS.UNVERIFIED);
      }

      if (data === "reset-password") {
        selectedTakeAction.value = {
          link: selectOption.value,
          label: "Reset Password",
        };
        loadUser("all");
      }
      if (data === "update-profile") {
        selectedTakeAction.value = {
          link: selectOption.value,
          label: "Edit User Info",
        };
        loadUser("all");
      }
      if (data === "message") {
        selectedTakeAction.value = {
          link: selectOption.value,
          label: "Message",
        };
        loadUser("all");
      }
      if (data === "flag") {
        selectedTakeAction.value = { link: selectOption.value, label: "Flag" };
        loadUser(TAKEACTION.FLAG);
      }
      if (data === "suspend") {
        selectedTakeAction.value = {
          link: selectOption.value,
          label: "Suspend",
        };
        loadUser(TAKEACTION.ACTIVE);
      }
      if (data === "deactivate") {
        selectedTakeAction.value = {
          link: selectOption.value,
          label: "Deactivate",
        };
        loadUser(TAKEACTION.ACTIVE);
      }
      if (data === "reactivate") {
        selectedTakeAction.value = {
          link: selectOption.value,
          label: "Reactivate",
        };
        loadUser(GENERALSTATUS.DEACTIVATED);
      }
      if (data === "delete") {
        selectedTakeAction.value = {
          link: selectOption.value,
          label: "Delete",
        };
        loadUser("all");
      }
    }
    watch(
      () => props.userType,
      (newValue) => setFilter(newValue)
    );
    watch(
      () => props.filter,
      (value) => {
        if(value !== '') {
          loadUser(undefined)
        }
        if(value === '') {
          loadUser('all')
        }
      }
    );
    watch(
      () => props.headerFilter,
      (newValue) => loadUser(newValue !== null ? "headerFilter" : "all")
    );
    watch(
      () => currentPage.value,
      () =>
        loadUser(
          filterType.value !== undefined
            ? filterType.value
            : selectOption.value !== undefined
            ? selectedTakeAction.value.link
            : "all"
        )
    );

    return {
      userList,
      getLastLogin,
      checkFlag,
      goToAction,
      memberships,
      userActions,
      showProfile,
      userAccount,
      showModal,
      cancel,
      selectMessageToSend,
      imageUrlAlt,
      messageUser,
      type,
      isMessage,
      birthdayMessage,
      selectedUser,
      isMessageShow,
      selectedType,
      cancelMessage,
      selectedTakeAction,
      addNotes,
      notesAccount,
      done,
      editNotes,
      viewNotes,
      isPreview,
      note,
      isEdit,
      isLoading,
      currentPage,
      onPageChange,
      totalPage,
      selectOption,
      filterType,
      showResetPasswordModal,
      userNameAccountSelected,
      resetPassword,
      resetPasswordNext,
      notesTitle,
      setFilter
    };
  },
  created: function () {
    this.moment = moment;
  },
};
</script>
<style scoped>
.color-gray {
  color: #727e8b;
}
.form-control:focus {
  box-shadow: none;
}
.btn:focus {
  box-shadow: none;
}
.color-gold {
  color: #b4914d;
  text-decoration: none;
  font-weight: 600;
}
.top-70 {
  top: 0% !important;
}
.start-58 {
  left: 58% !important;
}
.card {
  border: 0;
}
.widget-user-header {
  padding: 0;
  height: auto !important;
}
.widget-user .widget-user-header {
  padding: 0px;
}
.downloadAndMessageAlign {
  text-align: center;
  position: inherit;
  margin-top: 15px;
}
.dropdown-menu-lg {
  min-width: auto;
}
.link-name {
  color: rgb(85, 85, 194);
}
.link-name:hover {
  cursor: pointer;
}
.fa {
  color: black;
}
.fas:hover {
  cursor: pointer;
}
.fs-14 {
  font-size: 14px !important;
}

.table-responsive {
  margin-top: -35px;
}
.tbl-ll-date {
  color: #6c757d;
  position: relative;
  left: 25px;
  float: left;
  margin-top: -5px;
}
.tbl-img {
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
}
.profile-img {
  object-fit: contain;
  border-radius: 0.25em 0.25em 0em 0px;
  width: 100%;
}
.table-items th {
  padding-bottom: 2px;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
}
.table-items tbody,
tr,
td {
  white-space: nowrap !important;
}
.background-gold {
  background: gold;
}
.txt-color-gray {
  color: #6c757d;
}
.fa-circle-plus,
.fa-pen-to-square,
.fa-magnifying-glass-plus:hover {
  cursor: pointer;
}
</style>
