<template>
  <div class="row">
    <div
      class="col-md-12 col-lg-3 color-gray"
      :class="showTotal ? 'col-xl-2' : 'col-xl-4'"
    >
      <h3>{{ headTitle }}</h3>
    </div>
    <div v-if="showSearch" class="col-md-12 col-lg-4 col-xl-4">
      <div class="input-group">
        <span class="input-group-text"
          ><i class="fa fa-search color-gray"></i
        ></span>
        <input
          type="text"
          class="form-control"
          placeholder="Search"
          v-model="searchField"
          @input="onEnter"
        />
      </div>
      <div class="search">
        <small class="color-gold" @click="resetAll">Reset All</small>
      </div>
    </div>
    <div v-if="showSearch" class="col-md-12 col-lg-4 col-xl-4">
      <div class="input-group">
        <input
          v-model="cityState"
          type="text"
          class="form-control"
          placeholder="Location (City & State)"
          @input="onEnter"
        />
      </div>
      <div class="float-end search" @click="showAdvanceSearch">
        <small class="color-gold">Advance Search</small>
      </div>
    </div>
    <div v-if="showTotal" class="col-md-12 col-lg-2 col-xl-2">
      <span class="color-gray mt-1 float-end"
        >Total {{ title }}: {{ total }}</span
      >
    </div>
  </div>
  <modal-component
    :modal-id="`advance-search`"
    :header-style="`bg-linear`"
    :size="`modal-md`"
    :profile="false"
  >
    <template #modalBody>
      <div class="modal-body">
        <div class="mr-3 ml-3">
          <div class="row">
            <strong><h4>Search Filter</h4></strong>
            <filter-user-component
              @search="advanceSearch($event)"
              @reset="resetAll"
            />
          </div>
        </div>
      </div>
    </template>
  </modal-component>
</template>
<script>
import { ref, computed } from "vue";
import debounce from "lodash/debounce";
import $ from "jquery";
import ModalComponent from "./ModalComponent.vue";
import FilterUserComponent from "./FilterUserComponent.vue";
import LocationField from "./SingleComponent/LocationField.vue";

import { useTitle } from "@/Use/global.js";

export default {
  components: {
    ModalComponent,
    FilterUserComponent,
    LocationField,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    total: {
      type: Number,
      default: 0,
    },
    showTotal: {
      type: Boolean,
      default: true,
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    queryType: {
      type: String,
      default: null,
      required: false,
    },
    queryFilter: {
      type: String,
      default: null,
      required: false,
    },
    searchFor: {
      type: String,
      default: "users",
      required: true,
    },
    gatheringType: {
      type: Number,
      default: 0,
      required: false,
    },
    moduleName: {
      type: String,
      default: "user",
      required: true,
    },
  },
  emits: ["done", "search-filter", "search-filter-advance", "reset"],
  setup(props, { emit }) {
    const searchField = ref(null);
    const cityState = ref(null);
    const isSubmittingKeyword = ref(false);

    const arrSuggestions = ref([]);

    const { dynamicTitle } = useTitle({
      module: props.moduleName.toLowerCase(),
    });

    const headTitle = computed(() =>
      props.title == "" ? dynamicTitle(props.queryType) : props.title
    );

    const showAdvanceSearch = () => {
      $("#advance-search").modal("show");
    };

    const advanceSearch = (event) => {
      emit("search-filter-advance", event);
    };

    const resetAll = () => {
      searchField.value = null;
      cityState.value = null;
      emit("reset", "all");
    };

    /* Methods */
    const onEnter = debounce(() => {
      const form = ref(
        `header_search=1&search_field=${searchField.value}&city_state=${cityState.value}`
      );

      if (props.gatheringType !== 0) {
        form.value = `${form.value}&gathering_type=${props.gatheringType}`;
      }
      emit("search-filter", form.value);
    }, 750);

    const updateSuggestions = (value) => {
      arrSuggestions.value = [];
    };

    return {
      showAdvanceSearch,
      advanceSearch,
      resetAll,
      searchField,
      cityState,
      onEnter,
      isSubmittingKeyword,
      arrSuggestions,
      updateSuggestions,
      dynamicTitle,
      headTitle,
    };
  },
};
</script>
<style scoped>
.search-custom {
  height: 38px !important;
  background: #fff !important;
}
</style>
