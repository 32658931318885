<template>
  <div class="card p-0 mt-5">
    <div class="table-responsive">
      <table class="table table-items">
        <thead>
          <tr>
            <th scope="col">
              <h1 class="fs-6 fw-bold color-gray">
                <input type="checkbox" id="checkAll" />
                <span class="ml-3">{{
                  dynamicWordFor("Events").toUpperCase()
                }}</span>
              </h1>
            </th>
            <th scope="col">
              <h1 class="fs-6 fw-bold color-gray">EVENT OWNER</h1>
            </th>
            <th scope="col">
              <h1 class="fs-6 fw-bold color-gray"># OF MEMBERS</h1>
            </th>
            <th scope="col">
              <h1 class="fs-6 fw-bold color-gray">STATUS</h1>
            </th>
            <th scope="col">
              <h1 class="fs-6 fw-bold color-gray text-center">TAKE ACTION</h1>
            </th>
            <th scope="col">
              <h1 class="fs-6 fw-bold color-gray text-center">NOTES</h1>
            </th>
          </tr>
        </thead>
        <tbody v-if="isLoading">
          <tr>
            <td colspan="6" class="w-100 p-5 text-center">
              <div class="spinner-border text-gray" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="eventList.length === 0">
          <tr>
            <td colspan="6" class="w-100 p-5 text-center text-bold">
              Data not available
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr v-for="row in eventList" :key="row.in" class="fs-6">
            <td>
              <div class="icheck-primary d-inline">
                <input id="checkboxPrimary1" type="checkbox" />
                <span
                  class="ml-3 link-name"
                  data="modal"
                  @click="showEvent(row)"
                >
                  <img
                    class="rounded mr-3 tbl-img"
                    :src="row?.image_modified_url?.sizes?.sm"
                    @error="imageUrlAlt"
                  />
                  {{ row.title }}
                </span>
              </div>
            </td>
            <td>
              <span
                class="link-name"
                data="modal"
                @click="showProfile(row?.owner)"
              >
                <img
                  class="rounded mr-3 tbl-img"
                  :src="row?.owner?.primary_photo?.sizes?.sm"
                  @error="imageUrlAlt"
                />
                {{ row.owner?.first_name }} {{ row.owner?.last_name }}
              </span>
            </td>
            <td>{{ row.rsvpd_users_count }} Members</td>
            <td>
              {{ dynamicGeneralStatus(parseInt(row.status)) ?? row.status }}
            </td>
            <td class="text-center" v-if="queryType == `edit`">
              <a href="#" @click="goToAction('edit', row)" class="nav-link">
                Edit
              </a>
            </td>
            <td class="text-center" v-else-if="queryType == `publish`">
              <a
                href="#"
                @click="goToAction('publish', row)"
                class="nav-link p-0"
              >
                Publish
              </a>
            </td>
            <td class="text-center" v-else>
              <div
                v-if="
                  parseInt(row.status) !== GENERALSTATUS.DELETED ||
                  row.deleted_at === null
                "
              >
                <a class="nav-link" data-toggle="dropdown" href="#">
                  Take Action
                </a>
                <div class="dropdown-menu dropdown-menu-left">
                  <a
                    href="#"
                    @click="goToAction('edit', row)"
                    class="dropdown-item p-2"
                  >
                    Edit {{ dynamicWordFor("Event") }}
                  </a>
                  <a
                    href="#"
                    @click="goToAction('remove-flag', row)"
                    class="dropdown-item p-2"
                    v-if="parseInt(row.status) === GENERALSTATUS.FLAGGED"
                  >
                    Remove Flag
                  </a>
                  <a
                    href="#"
                    @click="goToAction('flag', row)"
                    class="dropdown-item p-2"
                    v-else
                  >
                    Flag
                  </a>

                  <a
                    href="#"
                    @click="goToAction('unsuspend', row)"
                    class="dropdown-item p-2"
                    v-if="parseInt(row.status) === GENERALSTATUS.SUSPENDED"
                  >
                    Unsuspend
                  </a>
                  <a
                    href="#"
                    @click="goToAction('suspend', row)"
                    class="dropdown-item p-2"
                    v-else
                  >
                    Suspend
                  </a>
                  <a
                    href="#"
                    @click="goToAction('publish', row)"
                    class="dropdown-item p-2"
                    v-if="
                      parseInt(row.status) === GENERALSTATUS.UNPUBLISHED ||
                      row.is_published === false
                    "
                  >
                    Publish
                  </a>
                  <a
                    href="#"
                    @click="goToAction('reactivate', row)"
                    class="dropdown-item p-2"
                    v-if="
                      parseInt(row.status) === GENERALSTATUS.DEACTIVATED ||
                      parseInt(row.status) === GENERALSTATUS.SUSPENDED
                    "
                  >
                    Reactivate
                  </a>
                  <a
                    href="#"
                    @click="goToAction('deactivate', row)"
                    class="dropdown-item p-2"
                    v-else
                  >
                    Deactivate
                  </a>
                  <a
                    href="#"
                    @click="goToAction('delete', row)"
                    class="dropdown-item p-2"
                  >
                    Delete
                  </a>
                </div>
              </div>
              <div v-else class="text-red">Not Available</div>
            </td>
            <td class="text-center">
              <span v-if="row.user_notes.length === 0">
                <i class="fas fa-circle-plus mr-2" @click="addNotes(row)"></i>
              </span>
              <span v-else>
                <i
                  class="fas fa-pen-to-square mr-2"
                  @click="editNotes(row)"
                ></i>
                <i
                  class="fas fa-magnifying-glass-plus"
                  @click="viewNotes(row)"
                ></i>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <pagination-component
      v-if="eventList.length > 0"
      class="mt-3 mb-2 mr-5"
      :total-pages="totalPage"
      :per-page="25"
      :current-page="currentPage"
      @pagechanged="onPageChange"
    />
  </div>

  <modal-component :modal-id="`modalId`">
    <template #modalBody>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-sm-12">
            <div class="row">
              <div class="col-md-5 col-lg-5 col-sm-12">
                <profile-image-component
                  v-if="userAccount"
                  :image-src="`${userAccount?.primary_photo?.modified}`"
                />
              </div>
              <div class="col-md-7 col-lg-7 col-sm-12">
                <user-information-component
                  v-if="userAccount"
                  :user="userAccount"
                  @cancel="cancel"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal-component>
  <modal-component :modal-id="`event-preview`" :size="`modal-md`">
    <template #modalBody>
      <div class="modal-body p-0">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-sm-12">
            <div class="w-100 h-100 position-relative">
              <div class="ce-utilities-aspect-ratio">
                <div
                  class="ce-utilities-aspect-ratio-wrapper restricted-aspect-ratio"
                >
                  <img
                    :src="event?.image_modified_url?.modified"
                    @error="imageUrlAlt"
                    alt="Perfect Friends"
                    class="mw-100 h-100 profile-img"
                  />
                </div>
              </div>
              <div class="col p-3">
                <div class="mt-2">
                  <h3>{{ event?.title }}</h3>
                </div>
                <div class="mt-4">
                  <strong>Moderator</strong>
                </div>
                <div class="mt-1">
                  <span
                    >{{ event?.owner?.first_name }}
                    {{ event?.owner?.last_name }}</span
                  >
                </div>
                <div class="mt-4">
                  <strong>About</strong>
                </div>
                <div class="mt-1">
                  <span>{{ event?.description }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer border-0 float-right">
            <span
              class="float-left link-profile cancel-profile"
              @click="cancelPreview"
              >Cancel</span
            >
            <span
              class="float-right link-profile view-profile"
              @click="viewEvent(event?.slug)"
              >View {{ dynamicWordFor("Event") }}</span
            >
          </div>
        </div>
      </div>
    </template>
  </modal-component>
  <modal-component
    :modal-id="`notes-modal`"
    :title="notesTitle"
    :header-style="`bg-linear`"
    :size="`modal-md`"
    :profile="false"
  >
    <template v-slot:modalBody>
      <notes-modal
        :notes-account="notesAccount"
        @done="done"
        :is-preview="isPreview"
        :notes="note"
        :is-edit="isEdit"
        :notes-type="`${dynamicWordFor('events')}`"
      />
    </template>
  </modal-component>
</template>
<script>
import { onMounted, ref, watch, computed } from "vue";
import $ from "jquery";
import { useStore } from "vuex";
import moment from "moment";
import router from "@/router";
import {
  GENERALSTATUS,
  TAKEACTION,
  userEnums,
  useGeneralStatus,
} from "@/composable/enums.js";
import ModalComponent from "@/components/ModalComponent.vue";
import ProfileImageComponent from "../ProfileImageComponent.vue";
import UserInformationComponent from "../../Pages/User/UserInformationModal.vue";
import PaginationComponent from "../PaginationComponent.vue";
import NotesModal from "../Modal/NotesModal.vue";
import {
  EVENTS_TAKE_ACTION,
  HUDDLES_TAKE_ACTION,
  GATHERING_TYPES,
  useEventTypeLabel,
  useDynamicWord,
} from "@/Use/events.js";

const eventTakeActions = [
  {
    label: "Edit Event",
    value: EVENTS_TAKE_ACTION.EDIT,
  },
  {
    label: "Flag",
    value: EVENTS_TAKE_ACTION.FLAG,
  },
  {
    label: "Suspend",
    value: EVENTS_TAKE_ACTION.SUSPEND,
  },
  {
    label: "Publish",
    value: EVENTS_TAKE_ACTION.PUBLISH,
  },
  {
    label: "Deactivate",
    value: EVENTS_TAKE_ACTION.DEACTIVATE,
  },
  {
    label: "Delete",
    value: EVENTS_TAKE_ACTION.DELETE,
  },
];

const huddleTakeActions = [
  {
    label: "Edit Huddle",
    value: EVENTS_TAKE_ACTION.EDIT,
  },
  {
    label: "Flag",
    value: HUDDLES_TAKE_ACTION.FLAG,
  },
  {
    label: "Suspend",
    value: HUDDLES_TAKE_ACTION.SUSPEND,
  },
  {
    label: "Deactivate",
    value: HUDDLES_TAKE_ACTION.DEACTIVATE,
  },
  {
    label: "Delete",
    value: HUDDLES_TAKE_ACTION.DELETE,
  },
];
export default {
  name: "App",

  components: {
    ModalComponent,
    ProfileImageComponent,
    UserInformationComponent,
    PaginationComponent,
    NotesModal,
  },
  props: {
    userType: {
      type: String,
    },
    viewType: {
      type: String,
      default: "view",
    },
    gatheringType: {
      type: Number,
      default: GATHERING_TYPES.EVENT,
    },
    queryType: {
      type: String,
      default: "all",
    },
    queryFilter: {
      type: String,
      default: null,
    },
    searchType: {
      type: String,
      default: "",
      required: false,
    },
    searchFilter: {
      type: String,
      default: "",
      required: false,
    },
  },
  emits: ["done"],
  setup(props, { emit }) {
    const store = useStore();
    const eventList = ref([]);
    const userAccount = ref(store.state.user.userList[0]);
    const { memberships, takeEventAction } = userEnums();
    const modalId = ref("view-profile");
    const isShowModal = ref(false);
    const event = ref(null);
    const currentPage = ref(1);
    const totalPage = ref(1);
    const selectedTakeAction = ref([]);
    const notesAccount = ref(null);
    const isPreview = ref(false);
    const isEdit = ref(false);
    const note = ref(null);
    const notesTitle = ref("Create Note");
    const isLoading = ref(false);

    const { dynamicWordFor } = useDynamicWord({
      gatheringType: props.gatheringType,
    });
    const { dynamicGeneralStatus } = useGeneralStatus();

    const takeActionMenu = computed(() => {
      return props.gatheringType === GATHERING_TYPES.EVENT
        ? eventTakeActions
        : huddleTakeActions;
    });

    const showModal = () => {
      $("#modalId").modal("show");
    };

    const cancel = () => {
      isShowModal.value = false;
      $("#modalId").modal("hide");
    };

    const selectMessageToSend = () => {
      $("#selectMessageType").modal("show");
    };

    const loadEvent = async (event) => {
      emit("done", true);
      localStorage.removeItem("statisticsType");
      isLoading.value = true;
      const response = await store.dispatch("loadEventList", {
        type: event,
        page_type: props.queryType,
        page_filter: props.queryFilter,
        perPage: 25,
        page: currentPage.value,
        gathering_type: props.gatheringType,
        search_type: props.searchType,
        search: props.searchFilter,
      });

      eventList.value = response.data;
      if (response) {
        totalPage.value = response.meta.last_page;
        emit("done", false);
        isLoading.value = false;
      }
    };

    const checkFlag = (row) => {
      const isFlag = row.filter((value) => value.type === "flag");
      return isFlag.length > 0;
    };

    const imageUrlAlt = (event) => {
      event.target.src = "//via.placeholder.com/150";
    };

    const goToAction = async (type, event) => {
      if (type === "edit") {
        router.push({
          name: `edit-${dynamicWordFor("event")}`,
          params: { id: event.id },
        });
      } else {
        router.push({
          path: `/${dynamicWordFor("event")}/${type}/${event.slug}`,
          query: { page_type: props.queryType, page_filter: props.queryFilter },
        });
      }
    };

    const showProfile = async (user) => {
      emit("done-loading", true);
      const data = JSON.parse(JSON.stringify(user));
      const response = await store.dispatch("loadUserProfile", {
        value: user.user_name,
      });
      localStorage.setItem("profile", response);
      userAccount.value = response;
      modalId.value = "user-" + data.id;
      $("#modalId").modal("show");
      emit("done-loading", false);
    };

    const showEvent = (row) => {
      event.value = row;
      $("#event-preview").modal("show");
    };

    const cancelPreview = () => {
      $("#event-preview").modal("hide");
    };

    const viewEvent = (slug) => {
      window.location.href = "/event/" + slug;
    };

    const onPageChange = (page) => {
      currentPage.value = page;

      loadEvent();
    };

    const editNotes = (account) => {
      isPreview.value = false;
      setTimeout(() => {
        isEdit.value = true;
        notesAccount.value = account;
        note.value = account.user_notes;
        notesTitle.value = "Edit Notes";
        $("#notes-modal").modal("show");
      }, 250);
    };
    const viewNotes = (account) => {
      isPreview.value = true;
      setTimeout(() => {
        isEdit.value = false;
        notesAccount.value = account;
        note.value = account.user_notes;
        notesTitle.value = "View Notes";
        $("#notes-modal").modal("show");
      }, 250);
    };
    const done = () => {
      loadEvent("all");
      notesAccount.value = "";
      $("#notes-modal").modal("hide");
    };
    const addNotes = (event) => {
      setTimeout(() => {
        note.value = "";
        isPreview.value = false;
        isEdit.value = false;
        notesAccount.value = event;
        $("#notes-modal").modal("show");
      }, 250);
    };

    onMounted(() => {
      loadEvent("all");
    });

    const setFilter = (data) => {
      if (data === "active") {
        loadEvent(TAKEACTION.ACTIVE);
      }
      if (data === "deactivated") {
        loadEvent(GENERALSTATUS.DEACTIVATED);
      }
      if (data === "unpublished") {
        loadEvent(GENERALSTATUS.UNPUBLISHED);
      }
      if (data === "flagged") {
        loadEvent(GENERALSTATUS.FLAGGED);
      }
      if (data === "suspended") {
        loadEvent(GENERALSTATUS.SUSPENDED);
      }
      if (data === "deleted") {
        loadEvent(GENERALSTATUS.DELETED);
      }

      if (data === "edit") {
        selectedTakeAction.value = {
          link: "edit",
          label: `Edit ${dynamicWordFor("Event")}`,
        };
        loadEvent("all");
      }
      if (data === "publish") {
        selectedTakeAction.value = {
          link: "publish",
          label: "Publish",
        };
        loadEvent(GENERALSTATUS.UNPUBLISHED);
      }
      if (data === "flag") {
        selectedTakeAction.value = {
          link: "flag",
          label: "Flag",
        };
        loadEvent(TAKEACTION.ACTIVE);
      }
      if (data === "suspend") {
        selectedTakeAction.value = {
          link: "suspend",
          label: "Suspend",
        };
        loadEvent(TAKEACTION.SUSPEND);
      }
      if (data === "deactivate") {
        selectedTakeAction.value = {
          link: "deactivate",
          label: "Deactivate",
        };
        loadEvent(TAKEACTION.DEACTIVATE);
      }
      if (data === "reactivate") {
        selectedTakeAction.value = {
          link: TAKEACTION.REACTIVATE,
          label: "Reactivate",
        };
        loadEvent(TAKEACTION.REACTIVATE);
      }
      if (data === "delete") {
        selectedTakeAction.value = {
          link: TAKEACTION.ACTIVE,
          label: "Delete",
        };
        loadEvent(TAKEACTION.ACTIVE);
      }
      if (data === "remove-flag") {
        selectedTakeAction.value = {
          link: GENERALSTATUS.FLAGGED,
          label: "Remove Flag",
        };
        loadEvent(GENERALSTATUS.FLAGGED);
      }
      if (data === "unsuspend") {
        selectedTakeAction.value = {
          link: GENERALSTATUS.SUSPENDED,
          label: "Unsuspend",
        };
        loadEvent(GENERALSTATUS.SUSPENDED);
      }
      if (data === "active") {
        selectedTakeAction.value = {
          link: TAKEACTION.ACTIVE,
          label: "Active",
        };
        loadEvent(TAKEACTION.ACTIVE);
      }

      if (data === undefined) {
        loadEvent("all");
      }
    };

    watch(
      () => [props.queryFilter, props.searchFilter],
      () => {
        loadEvent(props.queryType);
      }
    );

    return {
      eventList,
      checkFlag,
      goToAction,
      memberships,
      takeEventAction,
      showProfile,
      userAccount,
      showModal,
      cancel,
      selectMessageToSend,
      imageUrlAlt,
      showEvent,
      cancelPreview,
      event,
      currentPage,
      onPageChange,
      totalPage,
      selectedTakeAction,
      notesAccount,
      isPreview,
      isEdit,
      note,
      editNotes,
      viewNotes,
      done,
      addNotes,
      viewEvent,
      notesTitle,
      GATHERING_TYPES,
      dynamicWordFor,
      takeActionMenu,
      isLoading,
      setFilter,
      dynamicGeneralStatus,
      GENERALSTATUS,
    };
  },
  created: function () {
    this.moment = moment;
  },
};
</script>

<style scoped>
.color-gray {
  color: #727e8b;
}
.form-control:focus {
  box-shadow: none;
}
.btn:focus {
  box-shadow: none;
}
.color-gold {
  color: #b4914d;
  text-decoration: none;
  font-weight: 600;
}
.top-70 {
  top: 0% !important;
}
.start-58 {
  left: 58% !important;
}
.card {
  border: 0;
}
.widget-user-header {
  padding: 0;
  height: auto !important;
}
.widget-user .widget-user-header {
  padding: 0px;
}
.downloadAndMessageAlign {
  text-align: center;
  position: inherit;
  margin-top: 15px;
}
.dropdown-menu-lg {
  min-width: auto;
}
.link-name {
  color: rgb(85, 85, 194);
}
.link-name:hover {
  cursor: pointer;
}
.row-name {
  width: 20em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.view-profile {
  color: #b18d47;
}
.cancel-profile {
  color: #878787;
}
.link-profile:hover {
  cursor: pointer;
}
.table-responsive {
  margin-top: -35px;
}
.tbl-img {
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
}
.profile-img {
  object-fit: contain;
  border-radius: 0.25em 0.25em 0em 0px;
  width: 100%;
}

.table-items th {
  padding-bottom: 2px;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
}
.table-items tbody,
tr,
td {
  white-space: nowrap !important;
}

.fa-circle-plus,
.fa-pen-to-square,
.fa-magnifying-glass-plus:hover {
  cursor: pointer;
}
</style>
