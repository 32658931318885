import { GATHERING_TYPES } from "@/Use/events.js";

import ManageEvent from '@/Pages/Event/ManageEvent.vue'
import EventAction from '@/Pages/Report/UserAction.vue'

import AddEvent from '@/Pages/Event/AddEvent.vue'

import EditEvent from '@/Pages/Event/EditEvent.vue'
import EditNameLocation from '@/Pages/Event/EditNameLocation.vue'
import EditCategoryInterest from '@/Pages/Event/EditCategoryInterest.vue'
import EditDescription from '@/Pages/Event/EditDescription.vue'
import EditMedia from '@/Pages/Event/EditMedia.vue'
import EditAdmin from '@/Pages/Event/EditAdmin.vue'
import EditSchedule from '@/Pages/Event/EditSchedule.vue'

import ReviewAndPublish from '@/Pages/Event/ReviewAndPublish.vue'
import InvitePastEvents from '@/Pages/Event/InvitePastEvents.vue'
import InviteFriends from '@/Pages/Event/InviteFriends.vue'

import PreviewEvent from '@/Pages/Event/PreviewEvent.vue'

export default [{
        path: '/manage-events',
        name: 'ManageEvent',
        component: ManageEvent,
        meta: { requiresAuth: true },
        props: { moduleName: `event`, gatheringType: GATHERING_TYPES.EVENT }
    },
    {
        path: '/event/add',
        component: AddEvent,
        meta: { requiresAuth: true },
        props: { moduleName: `event`, gatheringType: GATHERING_TYPES.EVENT }
    },
    {
        path: '/event/:id/edit',
        name: 'edit-event',
        component: EditEvent,
        props: { moduleName: `event`, gatheringType: GATHERING_TYPES.EVENT },
        children: [
            {
              path: '',
              name: 'event-edit-index',
              component: ReviewAndPublish,
              meta: { requiresAuth: true },
            },
            {
              path: 'name-location',
              name: 'event-edit-name-location',
              component: EditNameLocation,
              meta: { requiresAuth: true },
            },
            {
              path: 'category-interest',
              name: 'event-edit-category-interest',
              component: EditCategoryInterest,
              meta: { requiresAuth: true },
            },
            {
              path: 'description',
              name: 'event-edit-description',
              component: EditDescription,
              meta: { requiresAuth: true },
            },
            {
              path: 'media',
              name: 'event-edit-media',
              component: EditMedia,
              meta: { requiresAuth: true },
            },
            {
              path: 'admin',
              name: 'event-edit-admin',
              component: EditAdmin,
              meta: { requiresAuth: true },
            },
            {
              path: 'schedule',
              name: 'event-edit-schedule',
              component: EditSchedule,
              meta: { requiresAuth: true },
            },
            {
              path: 'review-and-publish',
              name: 'event-edit-review-and-publish',
              component: ReviewAndPublish,
              meta: { requiresAuth: true },
            },
            {
              path: 'invite-past-events',
              name: 'event-edit-invite-past-events',
              component: InvitePastEvents,
              meta: { requiresAuth: true },
            },
            {
              path: 'invite-friends',
              name: 'event-edit-invite-friends',
              component: InviteFriends,
              meta: { requiresAuth: true },
            }
          ]
    },
    {
        path: '/event/publish-success',
        component: AddEvent,
        props: { moduleName: `event`, gatheringType: GATHERING_TYPES.EVENT },
        meta: { requiresAuth: true },
    },
    {
        path: '/event/:slug',
        component: PreviewEvent,
        name: 'PreviewEvent',
        props: { moduleName: `event`, gatheringType: GATHERING_TYPES.EVENT },
        meta: { requiresAuth: true },
    },
    {
        path: '/event/:slug/wall',
        name: 'EventWall',
        component: PreviewEvent,
        props: { moduleName: `event`, gatheringType: GATHERING_TYPES.EVENT },
        meta: { requiresAuth: true },
    },
    {
        path: '/event/:slug/discussion-board',
        name: 'EventDiscussion',
        component: PreviewEvent,
        props: { moduleName: `event`, gatheringType: GATHERING_TYPES.EVENT },
        meta: { requiresAuth: true },
    },
    {
        path: '/event/:slug/albums',
        name: 'EventAlbum',
        component: PreviewEvent,
        props: { moduleName: `event`, gatheringType: GATHERING_TYPES.EVENT },
        meta: { requiresAuth: true },
    },
    {
        path: '/event/flag/:slug',
        name: 'EventFlag',
        component: EventAction,
        props: { moduleName: `event`, queryFilter:`flagged`, gatheringType: GATHERING_TYPES.EVENT },
        meta: { requiresAuth: true },
    },
    {
        path: '/event/suspend/:slug',
        name: 'EventSuspend',
        component: EventAction,
        props: { moduleName: `event`, queryFilter:`suspended`, gatheringType: GATHERING_TYPES.EVENT },
        meta: { requiresAuth: true },
    },
    {
        path: '/event/deactivate/:slug',
        name: 'EventDeactivate',
        component: EventAction,
        props: { moduleName: `event`, queryFilter:`deactivated`, gatheringType: GATHERING_TYPES.EVENT },
        meta: { requiresAuth: true },
    },
    {
        path: '/event/reactivate/:slug',
        name: 'EventReactivate',
        component: EventAction,
        props: { moduleName: `event`, queryFilter:`reactivated`, gatheringType: GATHERING_TYPES.EVENT },
        meta: { requiresAuth: true },
    },
    {
        path: '/event/delete/:slug',
        name: 'EventDelete',
        component: EventAction,
        props: { moduleName: `event`, queryFilter:`deleted`, gatheringType: GATHERING_TYPES.EVENT },
        meta: { requiresAuth: true },
    },
    {
        path: '/event/publish/:slug',
        name: 'EventPublish',
        component: EventAction,
        props: { moduleName: `event`, queryFilter:`active`, gatheringType: GATHERING_TYPES.EVENT },
        meta: { requiresAuth: true },
    },
    {
        path: '/event/remove-flag/:slug',
        name: 'EventRemoveFlag',
        component: EventAction,
        props: { moduleName: `event`, queryFilter:`remove-flag`, gatheringType: GATHERING_TYPES.EVENT },
        meta: { requiresAuth: true },
    },
    {
        path: '/event/unsuspend/:slug',
        name: 'EventUnsuspend',
        component: EventAction,
        props: { moduleName: `event`, queryFilter:`unsuspended`, gatheringType: GATHERING_TYPES.EVENT },
        meta: { requiresAuth: true },
    },
]
