<template>
  <div class="row">
    <div
      v-for="(row, index) in card"
      :key="index"
      :class="type === 'users' ? `user-custom` : `col-sm-2`"
    >
      <div
        class="widget-user"
        :class="row.label.toLowerCase() === filterType ? 'active' : ''"
        @click="getStatus(row.label.toLowerCase())"
      >
        <div class="widget-user-header">
          <span class="widget-user-desc">{{ row.label }}</span>
          <h5 class="widget-user-desc">
            {{ row.value }}
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref, computed } from "vue";
import router from "@/router";

import { useRoute } from "vue-router";

export default {
  props: {
    card: {
      type: Array,
      default: ref("active"),
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: "users",
    },
    queryType: {
      type: String,
      default: null,
      required: false,
    },
    queryFilter: {
      type: String,
      default: null,
      required: false,
    },
    moduleName: {
      type: String,
      default: null,
      required: false,
    },
  },
  emits: ["change-status"],
  setup(props, { emit }) {
    const filterType = ref(null);

    const route = useRoute();

    onMounted(() => {
      filterType.value =
        props.queryFilter ?? router.currentRoute._value.query.filter;
    });

    const getStatus = (stat) => {
      filterType.value = stat;

      const query = { ...route.query, filter: stat };

      router.replace({ query });

      localStorage.setItem("statisticsType", stat);
      emit("change-status", stat);
    };

    return { getStatus, filterType };
  },
};
</script>

<style scoped>
.widget-user-header {
  padding: 0;
  height: auto !important;
}
.widget-user .widget-user-header {
  padding: 0px;
}
.active {
  border-bottom: 2px solid #b4914d;
}
.user-custom {
  max-width: 14%;
}
</style>
